/* Layout */
import Layout from '@/layout'
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    noCache: true                // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'   // 当路由设置了该属性，则会高亮相对应的侧边栏。
    affix: true                  // 如果设置为true，它则会固定在tags-view中(默认 false)
    tag:{                        // 如果设置tag，则左侧菜单会添加标识，type:success,warning,danger,info可选
        value:'爆',
        type:'danger'
    }
  }
 */

// 公共路由
export const constantRoutes = [
    {
        path: '/redirect',
        component: Layout,
        hidden: true,
        children: [
            {
                path: '/redirect/:path(.*)',
                component: resolve => require(['@/views/redirect'], resolve),
            },
        ],
    },
    {
        path: '/login',
        component: resolve => require(['@/views/login'], resolve),
        hidden: true,
    },
    {
        path: '/register',
        component: resolve => require(['@/views/register'], resolve),
        hidden: true,
    },
    {
        path: '/404',
        component: resolve => require(['@/views/error/404'], resolve),
        hidden: true,
    },
    {
        path: '/401',
        component: resolve => require(['@/views/error/401'], resolve),
        hidden: true,
    },
    // {
    //     path: '',
    //     component: Layout,
    //     redirect: 'index',
    //     // meta: { title: "控制台", icon: "dashboard", },
    //     children: [
    //         {
    //             path: 'index',
    //             component: resolve => require(['@/views/index'], resolve),
    //             name: 'Index',
    //             meta: { title: '首页', icon: 'guide', affix: true },
    //         },
    //     ],
    // },
    // {
    //     path: '/staff',
    //     component: Layout,
    //     hidden: true,
    //     redirect: 'noredirect',
    //     children: [
    //         {
    //             path: 'profile',
    //             component: resolve => require(['@/views/system/staff/profile/index'], resolve),
    //             name: 'Profile',
    //             meta: { title: '个人中心', icon: 'user' },
    //         },
    //     ],
    // },
    {
        path: '/basic',
        name: 'basic',
        component: Layout,

        type: 1,
        children: [{ component: resolve => require(['@/views/basic/info/index'], resolve), meta: { title: '基本信息', icon: 'el-icon-s-data' }, name: 'Info', path: 'info' }],
    },
    {
        path: '/content',
        redirect: 'article',
        type: 1,
        meta: { title: '内容管理', icon: 'el-icon-document' },
        component: Layout,
        children: [
            { component: resolve => require(['@/views/content/article/index'], resolve), meta: { title: '内容动态', icon: 'el-icon-document-copy' }, name: 'Article', path: 'article' },
            { component: resolve => require(['@/views/content/class/index'], resolve), meta: { title: '课程管理', icon: 'el-icon-video-camera-solid' }, name: 'Class', path: 'class' },
        ],
    },
    {
        name: 'lives',
        path: '/lives',
        redirect: 'lives',
        type: 1,
        meta: { title: '直播管理', icon: 'el-icon-video-camera-solid' },
        component: Layout,
        children: [
            { component: resolve => require(['@/views/lives/live/index'], resolve), meta: { title: '直播间管理', icon: 'el-icon-video-camera-solid' }, name: 'Live', path: 'live' },
            { component: resolve => require(['@/views/lives/anchor/index'], resolve), meta: { title: '主播管理', icon: 'el-icon-video-camera-solid' }, name: 'Anchor', path: 'anchor' },
            {
                component: resolve => require(['@/views/lives/liveReplay/index'], resolve),
                meta: { title: '直播回放管理', icon: 'el-icon-video-camera-solid' },
                name: 'LiveReplay',
                path: 'live_replay',
            },
        ],
    },
    {
        name: 'trains',
        path: '/trains',
        redirect: 'train',
        type: 1,
        meta: { title: '培训管理', icon: 'el-icon-s-help' },
        component: Layout,
        children: [{ component: resolve => require(['@/views/trains/train/index'], resolve), meta: { title: '培训列表', icon: 'el-icon-s-help' }, name: 'TrainIndex', path: 'train', type: 2 }],
    },
    {
        name: 'orders',
        path: '/orders',
        redirect: 'order',
        type: 1,
        meta: { title: '订单管理', icon: 'el-icon-s-order' },
        component: Layout,
        children: [{ component: resolve => require(['@/views/orders/order/index'], resolve), meta: { title: '订单列表', icon: 'el-icon-s-order' }, name: 'OrderIndex', path: 'order', type: 2 }],
    },
    {
        name: 'user_center',
        path: '/user_center',
        redirect: 'user_info',
        type: 1,
        meta: { title: '用户中心', icon: 'el-icon-user-solid' },
        component: Layout,
        children: [
            { component: resolve => require(['@/views/user_center/user_info/index'], resolve), meta: { title: '用户信息', icon: 'el-icon-user-solid' }, name: 'UserInfo', path: 'user_info', type: 2 },
        ],
    },
]

// 菜单
export default new Router({
    mode: 'history', // 去掉url中的#
    base: '/myadmin/',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
})
