import {getToken} from '@/utils/auth'
import {Message} from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import router from './router'
import store from './store'

NProgress.configure({showSpinner: false})
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const whiteList = [
    '/login',
    '/register',
    '/basic/info',
    '/content/article',
    '/content/class',
    '/lives/live',
    '/lives/anchor',
    '/lives/live_replay',
    '/orders/order',
    '/trains/train',
    '/user_center/user_info',
]

router.beforeEach((to, from, next) => {
    NProgress.start()
    if (getToken()) {
        to.meta.title && store.dispatch('settings/setTitle', to.meta.title)
        /* has token*/
        if (to.path === '/login') {
            next({path: '/basic/info'})
            NProgress.done()
        } else {
            if (store.getters.groups.length === 0) {
                store.dispatch('GenerateRoutes').then(accessRoutes => {
                    // console.log('accessRoutes------', accessRoutes)
                    // router.matcher = new Router().matcher
                    // 根据roles权限生成可访问的路由表
                    router.addRoutes(accessRoutes) // 动态添加可访问路由表
                    next({...to, replace: true}) // hack方法 确保addRoutes已完成
                })
            } else {
                next()
            }
        }
    } else {
        // 没有token
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next()
        } else {
            // next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
            next(`/basic/info`)
            NProgress.done()
        }
    }
})

router.afterEach(() => {
    NProgress.done()
})
